<template>
    <div @mouseenter="store.getComponentCursor($event, null)" class="loyalty-text innerContainer">
        <h2 class="header-title luxury">{{ item.title }}</h2>
        <div class="content" v-html="item.content"></div>
        <a :href="item.url" class="download-container">
            <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.79297 0.819902C5.79297 0.721429 5.50195 0.641602 5.14297 0.641602C4.78398 0.641602 4.49297 0.721429 4.49297 0.819902V9.71909L1.38779 6.55567C1.12389 6.28682 0.672259 6.26503 0.379038 6.50699C0.0858166 6.74896 0.0620464 7.16305 0.325945 7.4319L5.14258 12.3389L9.95921 7.4319C10.2231 7.16305 10.1993 6.74896 9.90612 6.50699C9.6129 6.26503 9.16126 6.28682 8.89736 6.55567L5.79297 9.71829L5.79297 0.819902Z"
                      fill="#940128"/>
            </svg>
            <span class="fira title">{{ $fn.tr('Download User guide') }}</span>
        </a>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useMenuStore} from "../../../store/pinia/menu";

let store = useMenuStore()
const props = defineProps({
    contentData: {
        type: Object,
    },
});
let title = computed(() => props.contentData?.conf?.header?.title)
let item = computed(() => {
    let item = props.contentData.data?.list?.[0]
    return {
        ...item,
        url: item.file?.[0]?.url
    }
})

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.loyalty-text {
    color: $primaryNavy;
    margin-top: 89px;

    .header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 55px;
        text-transform: uppercase;
        @media (max-width: 1650px) {
            font-size: 34px;
        }
    }

    .content:deep {
        ul {
            list-style: none;
            margin-top: 21px;

            li {
                font-family: 'FiraGO';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;

                &::before {
                    content: "\2022";
                    color: $burgundy;
                    font-weight: bold;
                    display: inline-block;
                    font-size: 30px;
                    width: 20px;
                    font-family: 'FiraGO';
                }
            }
        }
    }

    .download-container {
        display: flex;
        align-items: center;
        margin-top: 21px;
        color: $primaryNavy;

        .title {
            margin-left: 13px;
            font-weight: 700;
            font-size: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .loyalty-text {
            color: white;
        }

        .download-container {
            color: white;

        }
    }
}

</style>
